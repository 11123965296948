import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import ym from 'react-yandex-metrika'

import Header from './components/header'
import Footer from './components/footer'
import AnimatedText from './components/animatedText'

import classes from './styles/pages/ComingSoon.module.scss'

import MainPic from './img/main_pic.svg'
import Telegram from './img/telegram.svg'

function App() {
  const isMediumScreen = useMediaQuery({ maxWidth: 1200})
  const isTablet = useMediaQuery({ maxWidth: 1024 })
  const { t } = useTranslation();

  
  return (
    <div className={classes.container}>
      <Header/>
      <main className={classes.main}>
        <div className={classes.main_img}>
          <img src={MainPic} alt="main_image"/>
        </div>
        <div className={classes.main_info}>
          <div className={classes.main_info__title}>
            {isMediumScreen ? <h1>{t('uniting')} {t('companies')} <div><AnimatedText/></div></h1> :
            isTablet ? <h1>{t('uniting')} {t('companies')} <AnimatedText/></h1> :
            <h1><div>{t('uniting')}</div>{t('companies')} <AnimatedText/></h1> 
            }
          </div>
          <div className={classes.main_info__presentation}>
            <p>{t('learn')}</p>
            <p>{t('partners')}</p>
            <div className={classes.main_info__buttons}>
              <a href="/presentation.pdf" className={classes.presentation_btn} onClick={() => ym('reachGoal','presentation click')}>
                <span>{t('download')}</span>
                </a>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSeQTG1CfNTiNr19AECpMWoevitwSGUtjbLdt9Nm5DDLi9iY2A/viewform?usp=sf_link" target="_blank" rel="noreferrer" onClick={() => ym('reachGoal','google form click')}>{t('form')}</a>
            </div>
          </div>
          <div className={classes.main_info__telegram}>
            <p>{t('telegram')}</p>
            <a href="https://t.me/joinchat/AAAAAFTK147bLcbaEenhGg" yonClick={() => ym('reachGoal','tg channel click')}>
              <img src={Telegram} alt="telegram"/>
              <p>t.me/gocollaborate</p>
            </a>
          </div>
        </div>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
