import React, {useState} from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next';
import ym from 'react-yandex-metrika'
import classes from './footer.module.scss'
import email from '../../img/email.svg'


const Footer = () => {
    const isTablet = useMediaQuery({ maxWidth: 1024 })
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();
    const buttonText = submitted ? t('sent') : t('send');
    const submitClassName = submitted ? `${classes.form_button} ${classes.form_button_submitted}` : `${classes.form_button}`;
    

    const handleSubmit = event => {
        event.preventDefault();
        console.log(submitted);
        setSubmitted(true);
    }
    return (
        <footer className={classes.footer}>
            {isTablet ?
            <>
            <div className={classes.footer_form}>
            <p>{t('email')}</p>
            <form className={classes.footer_form__container} onSubmit={handleSubmit}> 
                <input type="email" placeholder='email'/>
            <button className={submitClassName}type="submit">{buttonText}</button>
            </form>
            </div>
            <div className={classes.footer_contacts}>
                <div className={classes.footer_email}>
                    <img src={email} alt="email"/>
                    <p><a href="mailto:anastasia.kharina@cogo.digital">anastasia.kharina@cogo.digital</a></p>
                </div>
            </div>
            </> :
            <>
            <div className={classes.footer_contacts}>
                <div className={classes.footer_email}>
                    <img src={email} alt="email"/>
                    <a href="mailto:anastasia.kharina@cogo.digital">anastasia.kharina@cogo.digital</a>
                </div>
            </div>
            <div className={classes.footer_form}>
            <p>{t('email')}</p>
            <form className={classes.footer_form__container} onSubmit={handleSubmit}> 
                <input type="email" placeholder='email'/>
            <button className={submitClassName} type="submit" onClick={() => ym('reachGoal','email form')}>{buttonText}</button>
            </form>
            </div>
            </>
            }
            
        </footer>
    )
}

export default Footer;