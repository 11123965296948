import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      uniting: 'Uniting',
      companies: 'companies and executors in IT to',
      success: 'achieve common success',
      projects: 'new projects realization',
      improvement: 'improve existing IT services',
      learn: 'Learn more about our company by downloading presentation',
      partners: 'Do you want to be a partner? Fill the form at the link',
      download: 'Download presentation',
      form: 'Fill the form',
      telegram: 'Our telegram-channel with actual vacancies',
      email: 'Leave your email and we will contact you',
      send: 'Send',
      sent: 'Sent'
    },
  },
  ru: {
    translation: {
      uniting: 'Объединяем',
      companies: 'компании и исполнителей в ИТ для',
      success: 'достижения общего успеха',
      projects: 'реализации новых проектов',
      improvement: 'улучшения ИТ услуг',
      learn: 'Узнать более детально о нашей компании вы можете, скачав презентацию',
      partners: 'Хотите стать партнером проекта? Заполните форму по ссылке',
      download: 'Скачать презентацию',
      form: 'Заполнить форму',
      telegram: 'Наш telegram-канал с актуальными вакансиями',
      email: 'Оставьте свой email и мы свяжемся с Вами',
      send: 'Отправить',
      sent: 'Отправлено'
    },
  },
};

const lng = window.navigator.language.slice(0, 2);
const lngStatus = lng === 'en' ? 'en' : 'ru';

i18n.use(initReactI18next).init({
  resources,
  lng: lngStatus,

  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: false,
    useSuspense: false,
  },
});

export default i18n;
