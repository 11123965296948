import React, {useEffect, useState} from 'react'
import classes from './header.module.scss'
import logo from '../../img/logo.svg'
import arrow from '../../img/switch-arrow.svg'
import { useTranslation } from 'react-i18next'

const Header = () => {
    const [activeLanguage, setActiveLanguage] = useState('ru');
    const [prevLanguage, setPrevLanguage] = useState('en');
    const [toggleLanguage, setToggleLanguage] = useState(false);
    const { i18n } = useTranslation();

    useEffect(() => {
        const language = window.navigator.language.slice(0,2);
        if(language === 'en') {
            i18n.changeLanguage('en');
            setActiveLanguage('en');
            setPrevLanguage('ru');
        }
    },[i18n])

    const handleToggle = (event) => {
    setToggleLanguage(!toggleLanguage);
  }

    const changeLanguage = () => {
        i18n.changeLanguage(prevLanguage);
        setActiveLanguage(prevLanguage);
        setPrevLanguage(activeLanguage);
        setToggleLanguage(!toggleLanguage);
    }

    const handleClick = (event) => {
    const id = event.target.id;
    console.log(id);
    if(toggleLanguage === true && event.target.id !== "switch_btn" && event.target.id !== "arrow" && event.target.id !== "prevLanguage") {
        setToggleLanguage(false);
    }
    }
    const switchClassName = toggleLanguage ? `${classes.switch_btn} ${classes.switch_btn_active}` : `${classes.switch_btn}`;
    const switchLanguageClassName = toggleLanguage ? `${classes.languageChange} ${classes.languageChange_active}` : `${classes.languageChange}`;
    return (
        <header className={classes.header} onClick={handleClick}>
            <div className={classes.header_logo}>
                <a href="/">
                   <img src={logo} alt="logo"/> 
                </a>
            </div>
            <div className="translate_btn">
                <button type="button" className={switchClassName} onClick={handleToggle} id="switch_btn">
                    {activeLanguage === 'en' ? 'En' : 'Ru'}
                <img src={arrow} alt="arrow" id="arrow"/>
                </button>
                <div className={classes.languageChangeSection}>
    <button type="button" className={switchLanguageClassName} id="prevLanguage" name={prevLanguage} onClick={changeLanguage}>{prevLanguage === 'en' ? 'En' : 'Ru'}</button>
                </div>
            </div>
        </header>
    )
}

export default Header;