import React, {useEffect} from 'react';
import './animatedText.scss'
import {useTranslation} from 'react-i18next'

const AnimatedText = () => {
  const { t } = useTranslation();
  const resetAnimation = () => {
    const words = document.querySelectorAll('.word');
    words.forEach((word) => {
      word.style.opacity = 0;
    });
  };
  const animateFunction = () => {
    let words = document.querySelectorAll('.word');
    words.forEach((word) => {
      let letters = word.textContent.split('');
      word.textContent = '';
      letters.forEach((letter) => {
        let span = document.createElement('span');
        span.textContent = letter;
        span.className = 'letter';
        if (letter === ' ') {
          span.textContent = '';
          span.innerHTML = '&nbsp;';
        }
        word.append(span);
      });
    });

    let currentWordIndex = 0;
    let maxWordIndex = words.length - 1;
    words[currentWordIndex].style.opacity = '1';

    let rotateText = () => {
      let currentWord = words[currentWordIndex];
      let nextWord =
        currentWordIndex === maxWordIndex
          ? words[0]
          : words[currentWordIndex + 1];
      Array.from(currentWord.children).forEach((letter, i) => {
        setTimeout(() => {
          letter.className = 'letter out';
        }, i * 80);
      });
      nextWord.style.opacity = '1';
      Array.from(nextWord.children).forEach((letter, i) => {
        letter.className = 'letter behind';
        setTimeout(() => {
          letter.className = 'letter in';
        }, 340 + i * 80);
      });
      currentWordIndex =
        currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
    };
    rotateText();
    const interval = setInterval(rotateText, 4000);
    return interval;
  };
  useEffect(() => {
    const interval = animateFunction();
    return () => {
      clearInterval(interval);
      resetAnimation();
    };
  }, [t]);

  return (
    <span className="rotating-text">
      <p>
        <span className="word one">{t('success')}</span>
        <span className="word two">{t('improvement')}</span>
        <span className="word three">{t('projects')}</span>
      </p>
    </span>
  );
};

export default AnimatedText;
