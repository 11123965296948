import React from 'react';
import ReactDOM from 'react-dom';
import ComingSoon from './ComingSoon';
import reportWebVitals from './reportWebVitals';

import './styles/_globals.module.scss';
import './translations/i18n';
import {YMInitializer} from 'react-yandex-metrika'


ReactDOM.render(
   <>
    <YMInitializer accounts={[70311199]}/>
    <ComingSoon />
    </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
